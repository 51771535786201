import React, { lazy, Suspense, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  GET_CASE_ENDPOINT_CONSTANT,
  AUTH_HEADER_TOKEN,
  NATURE_OF_SUIT,
  Koloden_Trademarks_Heading_CHN,
  Koloden_Goods_Services_CHN,
  Koloden_Image_CHN,
  Koloden_Registration_No_CHN,
  Koloden_Word_Mark_Matches_CHN,
  Table_Date_Filed_CHN,
  Table_Case_Number_CHN,
  Table_Court_Name_CHN,
  Table_Case_Link_CHN,
  Table_Nature_Of_Suit_CHN
} from "../Constants/backend-api.constant";
import { FormatDate } from "../Utils/formatters.utils";

// Components
import LoadingBlock from "../Essentials/LoadingBlock";
const Header = lazy(() => import("../Essentials/Header"));
const Footer = lazy(() => import("../Essentials/Footer"));
const Attorney = lazy(() => import("../Essentials/Attorney"));

const CaseDetail = (props) => {
  const [caseDetail, setCaseDetail] = useState(null);
  const params = useParams();
  const chinese = "chn";
  const currTranslation = localStorage.getItem("translation");

  useEffect(() => {
    getCaseDetailById();
  }, []);

  const getCaseDetailById = () => {
    const loaderBlocker = document.querySelector('.loaderBlocker');
    loaderBlocker.style.display="block";
    fetch(`${GET_CASE_ENDPOINT_CONSTANT}/${params.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: AUTH_HEADER_TOKEN,
      },
    })
      .then((response) => {
        if (response.ok) {
          loaderBlocker.style.display="none";
          return response.json();
        }
        throw response;
      })
      .then((caseItem) => {
        setCaseDetail(caseItem);
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <LoadingBlock />
      <Suspense fallback={LoadingBlock()}>
        <Header />
      </Suspense>
      <div className="breadcrumb">
        <div className="container">
          <div className="col-12">
            <ul>
              <li>
                <Link to="/">Seller Litigation</Link>
              </li>
              <li>
                <Link to="/">Case List</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="about-content">
        <div className="container">
          {caseDetail ? (
            <div className="col-12">
              <h1 className="mb-4">{caseDetail.case_title}</h1>
              <table className="table-about">
                <tbody>
                  <tr>
                    <td>{currTranslation == chinese ? Table_Case_Number_CHN : "Case Number"}</td>
                    <td>{caseDetail.case_full_number}</td>
                  </tr>
                  <tr>
                    <td>{currTranslation == chinese ? Table_Date_Filed_CHN : "Date Filed"}</td>
                    <td>
                      {FormatDate(caseDetail.date_filed)}
                    </td>
                  </tr>
                  <tr>
                    <td>{currTranslation == chinese ? Table_Court_Name_CHN : "Court"}</td>
                    <td>{caseDetail.court_name}</td>
                  </tr>
                  <tr>
                    <td>{currTranslation == chinese ? Table_Nature_Of_Suit_CHN : "Nature of Suit"}</td>
                    <td>
                      {
                        NATURE_OF_SUIT.find(
                          (item) => item.code == caseDetail.nature_of_suit
                        ).label
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>{currTranslation == chinese ? Table_Case_Link_CHN : "Case Link"}</td>
                    <td>
                      <a href={caseDetail.case_link} target="_blank">
                        {caseDetail.case_link}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            ""
          )}

          {caseDetail ? (
            <div className="col-12">
              <h1 className="mt-5 mb-4">
                {currTranslation == chinese ? Koloden_Trademarks_Heading_CHN : "Potential Relevant Trademark Registrations from Koloden®:"}
              </h1>
              <div className="tableContent">
              <table className="table-about bordered">
                <thead>
                  <tr>
                    <th>{currTranslation == chinese ? Koloden_Word_Mark_Matches_CHN : "Word Mark Matches"}</th>
                    <th className="align-cent">{currTranslation == chinese ? Koloden_Registration_No_CHN : "U.S. Reg. No."}</th>
                    <th className="align-cent">{currTranslation == chinese ? Koloden_Image_CHN : "Cited Reference Image"}</th>
                    <th>{currTranslation == chinese ? Koloden_Goods_Services_CHN : "Goods & Services"}</th>
                  </tr>
                </thead>
                <tbody>
                  {caseDetail?.trademarks?.length ? (
                    caseDetail.trademarks.map((trademark) => (
                      <tr key={trademark.id}>
                        <td>{trademark.trademark}</td>
                        <td>{trademark.registration_number}</td>
                        <td>
                          <img src={trademark.image_url}></img>
                        </td>
                        <td><div className="goodServ">{trademark.goods_services}</div></td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4}>No matching registration found</td>{" "}
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
            </div>
          ) : (
            <div className="loading-case">Loading.. Please wait</div>
          )}
        </div>
      </div>
      <Suspense fallback={LoadingBlock()}>
        <Attorney></Attorney>
        <Footer></Footer>
      </Suspense>
    </div>
  );
};

export default CaseDetail;
