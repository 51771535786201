import React, { useState, useEffect } from "react";
import {
  SEND_EMAIL_ENDPOINT,
  AUTH_HEADER_TOKEN,
  Contact_FirstName_Label_CHN,
  Contact_LastName_Label_CHN,
  Contact_Email_Label_CHN,
  Contact_Phone_Label_CHN,
  Contact_Captcha_Label_CHN,
  Contact_Message_Label_CHN,
  Contact_Disclaimer_Label_CHN,
  Contact_Success_Message_CHN,
} from "../Constants/backend-api.constant";
import {
  getPageContentUtil,
  getPageContentsFromResultsUtil,
} from "../Components/_common/page-content.utils";
import { CreateCaptcha } from "../Utils/captcha";


const Attorney = (props) => {
  const currTranslation = localStorage.getItem("translation");
  const chinese = "chn";
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [captchaValue, setCaptchaValue] = useState("");
  const [captchaCode, setCaptchCode] = useState("");

  const [formTitle, setFormTitle] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [disclaimerText, setDisclaimerText] = useState("");
  const successMessageText =
    currTranslation == chinese
      ? Contact_Success_Message_CHN
      : "Thank you for your message. We will be in contact where appropriate.";

  useEffect(() => {
    createCaptchaHTml();

    getPageContentUtil("contact", currTranslation)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.results?.length) {
          const pageData = getPageContentsFromResultsUtil(
            response?.results,
            "contact"
          );
          if (pageData?.length) {
            // Form Heading
            const formHeading = pageData.find(
              (data) => data.item_name == "contact_form_heading"
            );
            setFormTitle(formHeading?.english_text);
            if (currTranslation == chinese) {
              setFormTitle(formHeading.chinese_text);
            }
            // Form BUttonText
            const buttonT = pageData.find(
              (data) => data.item_name == "contact_form_submit_bt_text"
            );
            setButtonText(buttonT?.english_text);
            if (currTranslation == chinese) {
              setButtonText(buttonT.chinese_text);
            }
            // Disclaimer text
            const disclaimerT = pageData.find(
              (data) => data.item_name == "contact_disclaimer_text"
            );
            setDisclaimerText(disclaimerT?.english_text);
            if (currTranslation == chinese) {
              setDisclaimerText(disclaimerT.chinese_text);
            }
          }
        }
      });
  }, []);

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setPhoneNumber("");
    setMessage("");
    setCaptchaValue("")
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const token =  await recaptchaRef.current.executeAsync();
    // if(!token) {
    //   setErrorMessage(`Error: Invalid token`);
    //   return;
    // } else {
    //   recaptchaRef.current.reset();
    // }

    const isValidCode = verifyCode();
    if (!isValidCode) {
      setErrorMessage(`Error: Invalid Captch Code`);
      createCaptchaHTml();
      return;
    } else {
      setErrorMessage("");
      createCaptchaHTml();
    }

    const { firstName, lastName, emailAddress, phoneNumber, message } =
      e.target.elements;
    const contactData = {
      firstName: firstName.value,
      lastName: lastName.value,
      emailAddress: emailAddress.value,
      phoneNumber: phoneNumber.value,
      message: message.value,
    };

    fetch(SEND_EMAIL_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: AUTH_HEADER_TOKEN,
      },
      body: JSON.stringify(contactData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return response.json();
        }
        //throw response;
      })
      .then((response) => {
        if (response?.message == "success") {
          resetForm();
          setSuccessMessage(successMessageText);
          setErrorMessage("");
          setTimeout(() => {
            setSuccessMessage("");
          }, 2000);
        } else {
          setErrorMessage(`Error: ${response?.message}`);
          setSuccessMessage("");
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage(`Error: ${error?.statusText}`);
        setSuccessMessage("");
      });
  };

  const createCaptchaHTml = () => {
    const code = CreateCaptcha();
    setCaptchCode(code);

    //clear the contents of captcha div first
    document.getElementById("captcha").innerHTML = "";
    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 50;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeStyle = "white";
    ctx.strokeText(code.join(""), 0, 30);
    document.getElementById("captcha").appendChild(canv); // adds the canvas to the body element
    return 1;
  };

  const verifyCode = () => {
    if (captchaValue == captchaCode.join("")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className="attorney">
        {successMessage ? (
          <div className="col-6 offset-3 alert alert-success">
            {successMessage}
          </div>
        ) : (
          ""
        )}
        {errorMessage ? (
          <div className="col-6 offset-3 alert alert-danger">
            {errorMessage}
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-12">
              <form
                onSubmit={handleSubmit}
                method="post"
                style={{ marginTop: "0px" }}
              >
                <div className="row">
                  <div className="col-12">
                    <h1>
                      {formTitle ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: formTitle,
                          }}
                        ></span>
                      ) : (
                        "TALK TO AN ATTORNEY"
                      )}
                    </h1>
                  </div>
                  <div className="col-6">
                    <input
                      name="firstName"
                      placeholder={
                        currTranslation == chinese
                          ? Contact_FirstName_Label_CHN
                          : "FIRST NAME"
                      }
                      required
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                    ></input>
                  </div>
                  <div className="col-6">
                    <input
                      name="lastName"
                      placeholder={
                        currTranslation == chinese
                          ? Contact_LastName_Label_CHN
                          : "LAST NAME"
                      }
                      required
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-6">
                    <input
                      name="emailAddress"
                      type="email"
                      placeholder={
                        currTranslation == "chn"
                          ? Contact_Email_Label_CHN
                          : "EMAIL"
                      }
                      required
                      value={emailAddress}
                      onChange={(e) => setEmailAddress(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-6">
                    <input
                      name="phoneNumber"
                      placeholder={
                        currTranslation == chinese
                          ? Contact_Phone_Label_CHN
                          : "PHONE/WECHAT ID"
                      }
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-12">
                    {/* <label>
                      {currTranslation == chinese
                        ? Contact_Message_Label_CHN
                        : "MESSAGE, CASE NUMBER"}
                    </label> */}
                    <textarea
                      name="message"
                      required
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      placeholder={
                        currTranslation == chinese
                          ? Contact_Message_Label_CHN
                          : "MESSAGE, CASE NUMBER"
                      }
                      rows={4}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <div style={{ paddingTop: "10px", paddingRight:"10px", color: "#97B7D1"}}>SECURITY CODE </div><div id="captcha"></div>
                    <input
                      name="captch"
                      placeholder={
                        currTranslation == chinese
                          ? Contact_Captcha_Label_CHN
                          : "ENTER SECURITY CODE"
                      }
                      required
                      value={captchaValue}
                      onChange={(e) => setCaptchaValue(e.target.value)}
                    ></input>
                  </div>
                  <div className="col-12">
                    <button type="submit">
                      {buttonText ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: buttonText,
                          }}
                        ></span>
                      ) : (
                        "SUBMIT MESSAGE"
                      )}
                    </button>
                  </div>
                  <div className="col-12">
                    <label className="mt-4">
                      {currTranslation == chinese
                        ? Contact_Disclaimer_Label_CHN
                        : "DISCLAIMER"}
                    </label>
                    <div className="attorney-disclaimer-text">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: disclaimerText,
                        }}
                      ></span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attorney;
