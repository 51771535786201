import './App.css';

import React from 'react'
import './App.css';
import './assets/css/sora-font.css'
// import './assets/css/bootstrap-icons.css';
import './assets/css/bootstrap.min.css';
import './assets/css/fonts.css';
// import './assets/css/font-awesome.css';
import './assets/css/custom.css';
import './assets/js/scripts.js';

// import "react-datepicker/dist/react-datepicker.css";


// Dashboard
import AllRoutes from "./Routes/allRoutes"

function App() {
  return (
    <AllRoutes></AllRoutes>
  );
}

export default App;
