import React, { useEffect, useState } from "react";
import {
  getPageContentUtil,
  getPageContentsFromResultsUtil,
} from "./_common/page-content.utils";
import { API_HOST } from "../Constants/backend-api.constant";
// Components
import Header from "../Essentials/Header";
import Footer from "../Essentials/Footer";
import Attorney from "../Essentials/Attorney";

const TermsOfUse = (props) => {
  const chinese = "chn";
  const currTranslation = localStorage.getItem("translation");

  // Page Content
  const [bannerText, setBannerText] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [termPage, setTermPage] = useState("");

  useEffect(() => {
    getPageContentUtil("term", currTranslation)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.results?.length) {
          const aboutPage = getPageContentsFromResultsUtil(
            response?.results,
            "term"
          );
          // Term banner Text
          const bannerText = aboutPage.find(
            (data) => data.item_name == "term_banner_text"
          );
          setBannerText(bannerText?.english_text);
          if (currTranslation == chinese) {
            setBannerText(bannerText.chinese_text);
          }
          // Banner Image
          const bannerImg = aboutPage.find(
            (data) => data.item_name == "term_banner_image"
          );
          const bannerImagePath = `${API_HOST}/uploads/${bannerImg?.english_text}`
          setBannerImage(bannerImagePath);

          // Term page text
          const termPageText = aboutPage.find(
            (data) => data.item_name == "term_page_text"
          );
          setTermPage(termPageText?.english_text);
          if (currTranslation == chinese) {
            setTermPage(termPageText.chinese_text);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <Header></Header>

      <div className="slider" style={{'backgroundImage': `url(${bannerImage})`}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 about">
              <h1>
                {bannerText ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: bannerText,
                    }}
                  ></span>
                ) : (
                  "TERMS OF USE"
                )}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="col-12">
            <br></br>
            {termPage ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: termPage,
                }}
              ></span>
            ) :""}
          </div>
        </div>
      </div>
      <Attorney></Attorney>
      <Footer></Footer>
    </div>
  );
};

export default TermsOfUse;
