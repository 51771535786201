import {
  GET_PAGE_CONTENT,
  AUTH_HEADER_TOKEN,
} from "../../Constants/backend-api.constant";

export const getPageContentUtil = (pageName = "home", translation = "eng") => {
  return fetch(
    `${GET_PAGE_CONTENT}?page_name=${pageName}&language=${translation}`,
    {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        token: AUTH_HEADER_TOKEN,
      },
    }
  );
};

export const getPageContentsFromResultsUtil = (results, pageName) => {
  if (!results || results.length == 0) return null;
  const pageContent = results.filter((result) => result.page_name == pageName);
  return pageContent;
};
