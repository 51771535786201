import React, { useEffect, useState } from "react";
// import {Link, useParams, useHistory } from 'react-router-dom'
import {
  getPageContentUtil,
  getPageContentsFromResultsUtil,
} from "../Components/_common/page-content.utils";

import facebook from "../assets/img/facebook.png";
import twitter from "../assets/img/twitter.png";
import linkedin from "../assets/img/linkedin.png";

const Footer = (props) => {
  const [footerText, setFooterText] = useState("");
  const [facebookLink, setFacebookLink] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [linkedinLink, setLinkedinLink] = useState("");

  const currTranslation = localStorage.getItem("translation");
  useEffect(() => {
    getPageContentUtil("footer", currTranslation)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.results?.length) {
          const footerData = getPageContentsFromResultsUtil(
            response?.results,
            "footer"
          );

          if (footerData?.length) {
            const copyright = footerData.find(
              (data) => data.item_name == "footer_copyright_text"
            );
            if (copyright) {
              setFooterText(`<a href="/terms-of-use" class="terms-of-use-link">${copyright.english_text}</a>`);
              if (currTranslation == "chn") {
                setFooterText(`<a href="/terms-of-use" class="terms-of-use-link">${copyright.chinese_text}</a>`);
              }
            }
            // Facebook link
            const facebookSource = footerData.find(
              (data) => data.item_name == "facebook_link"
            );
            if (facebookSource) {
              setFacebookLink(facebookSource.english_text);
              if (currTranslation == "chn") {
                setFacebookLink(facebookSource.chinese_text);
              }
            }
            // Twitter link
            const twitterSource = footerData.find(
              (data) => data.item_name == "twitter_link"
            );
            if (twitterSource) {
              setTwitterLink(twitterSource.english_text);
              if (currTranslation == "chn") {
                setTwitterLink(twitterSource.chinese_text);
              }
            }
            // LinkedIn link
            const linkedinSouce = footerData.find(
              (data) => data.item_name == "linkedin_link"
            );
            if (linkedinSouce) {
              setLinkedinLink(linkedinSouce.english_text);
              if (currTranslation == "chn") {
                setLinkedinLink(linkedinSouce.chinese_text);
              }
            }
          }
        }
      });
  });

  return (
    <div>
      <footer>
        <div className="container">
          <div className="row footerBx">
            <div className="col-md-9 col-12">
              <h1>
                {footerText ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: footerText,
                    }}
                  ></span>
                ) : (
                  "© 2023 Seller Litigation | TERMS OF USE"
                )}
              </h1>
            </div>
            <div className="col-md-3 col-12">
              <ul>
                <li>
                  <a href={facebookLink} target="_blank">
                    <div className="icon">
                      <img src={facebook} alt="Facebook" width={39} height={39}></img>
                    </div>
                  </a>
                </li>
                <li>
                  <a href={twitterLink} target="_blank">
                    <div className="icon">
                      <img src={twitter} alt="Twitter" width={39} height={39}></img>
                    </div>
                  </a>
                </li>
                <li>
                  <a href={linkedinLink} target="_blank">
                    <div className="icon">
                      <img src={linkedin} alt="LinkedIn" width={39} height={39}></img>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
