const apiHost = 'https://sellerlitigation.com:3000';
// const apiHost = 'http://localhost:3500';


export const GET_CASES_ENDPOINT_CONSTANT =
  `${apiHost}/cases/get-cases`;

export const GET_CASE_ENDPOINT_CONSTANT =
  `${apiHost}/cases/get-case`;

export const SEARCH_ENDPOINT = `${apiHost}/cases/search`;

export const SEND_EMAIL_ENDPOINT =
  `${apiHost}/contact/send-email`;

  export const GET_PAGE_CONTENT =
  `${apiHost}/cms/get-page-content`;

export const API_HOST = apiHost;

// Local Settings
//   export const GET_CASES_ENDPOINT_CONSTANT =
//   "http://localhost:3500/cases/get-cases";

// export const GET_CASE_ENDPOINT_CONSTANT =
//   "http://localhost:3500/cases/get-case";

// export const SEARCH_ENDPOINT = "http://localhost:3500/cases/search";

// export const SEND_EMAIL_ENDPOINT =
//   "http://localhost:3500/contact/send-email";

//   export const GET_PAGE_CONTENT =
//   "http://localhost:3500/cms/get-page-content";

//   export const API_HOST = "http://localhost:3500"

// Auth Token
export const AUTH_HEADER_TOKEN = "9091421232159532";
export const NATURE_OF_SUIT = [
  { code: 820, label: "Copyright" },
  { code: 830, label: "Patent" },
  { code: 840, label: "Trademark" },
];
  // Language Consts
  export const Header_Search_Bar_Placeholder_CHN = "原告/商标";
  // Table Fields
  export const Table_Date_Filed_CHN = "归档日期";
  export const Table_Case_Title_CHN = "个案名称";
  export const Table_Court_Name_CHN = "法庭";
  export const Table_Case_Number_CHN = "案件编号";
  export const Table_Trademarks_CHN = "商标*";
  export const Table_Export_CHN = "出口";
  export const Table_Previous_Nav_CHN = "以前的";
  export const Table_Next_Nav_CHN = "下一个";
  export const Table_Loading_CHN = "加载请稍候";
  export const Table_Nature_Of_Suit_CHN = "诉讼性质";
  export const Table_Case_Link_CHN = "案例链接";
  export const Table_Trademark_Note_Text_CHN = "* 商标数据可能不适用于所有情况。";

  // Contact Form Labels
  export const Contact_FirstName_Label_CHN = "名";
  export const Contact_LastName_Label_CHN = "姓";
  export const Contact_Email_Label_CHN = "电子邮件";
  export const Contact_Phone_Label_CHN = "电话/微信号";
  export const Contact_Captcha_Label_CHN = "图形验证码";
  export const Contact_Message_Label_CHN = "消息，案例编号";
  export const Contact_Disclaimer_Label_CHN = "免责声明";
  export const Contact_Success_Message_CHN = "感谢您的留言。我们将在适当的时候联系。";

  // Case Detail Page
  export const Koloden_Trademarks_Heading_CHN = "来自 Koloden® 的潜在相关商标注册：";
  export const Koloden_Word_Mark_Matches_CHN = "字标匹配";
  export const Koloden_Registration_No_CHN = "美国注册号";
  export const Koloden_Image_CHN = "引用参考图像";
  export const Koloden_Goods_Services_CHN = "商品与服务";

  // Search Page
  export const Search_Term_Text_CHN = "搜索词";
