import React, {lazy, Suspense, useEffect, useState } from "react";
import {
  getPageContentUtil,
  getPageContentsFromResultsUtil,
} from "./_common/page-content.utils";
import { API_HOST } from "../Constants/backend-api.constant";
import LoadingBlock from "../Essentials/LoadingBlock";
const Header = lazy(() => import("../Essentials/Header"));
const Footer = lazy(() => import("../Essentials/Footer"));
const Attorney = lazy(() => import("../Essentials/Attorney"));

const About = (props) => {
  const chinese = "chn";
  const currTranslation = localStorage.getItem("translation");

  // Page Content
  const [bannerText, setBannerText] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [aboutPage, setAboutPage] = useState("");

  useEffect(() => {
    getPageContentUtil("about", currTranslation)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.results?.length) {
          const aboutPage = getPageContentsFromResultsUtil(
            response?.results,
            "about"
          );
          // Banner Text
          const bannerText = aboutPage.find(
            (data) => data.item_name == "about_banner_text"
          );
          setBannerText(bannerText?.english_text);
          if (currTranslation == chinese) {
            setBannerText(bannerText.chinese_text);
          }
          // Banner Image
          const bannerImg = aboutPage.find(
            (data) => data.item_name == "about_banner_image"
          );
          const bannerImagePath = `${API_HOST}/uploads/${bannerImg?.english_text}`
          setBannerImage(bannerImagePath);

          // About page text
          const aboutPageText = aboutPage.find(
            (data) => data.item_name == "about_page_text"
          );
          setAboutPage(aboutPageText?.english_text);
          if (currTranslation == chinese) {
            setAboutPage(aboutPageText.chinese_text);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
 
      <Suspense fallback={LoadingBlock()}>
        <Header />
      </Suspense>

      <div className="slider" style={{'backgroundImage': `url(${bannerImage})`}}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 about">
              <h1>
                {bannerText ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: bannerText,
                    }}
                  ></span>
                ) : (
                  "ABOUT"
                )}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="col-12">
            <br></br>
            {aboutPage ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: aboutPage,
                }}
              ></span>
            ) :  ""}
          </div>
        </div>
      </div>
      <Suspense fallback={LoadingBlock()}>
        <Attorney></Attorney>
        <Footer></Footer>
      </Suspense>
    </div>
  );
};

export default About;
