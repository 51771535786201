import { ReCaptcha_Secret_Key } from "../../Constants/recaptch.constant";
export const focusCaseListingContent = (id) => {
  let position = document.getElementById(id);
  window.location.href = "#" + id; // changing the url
  position && position.scrollIntoView({ behavior: "smooth", block: "start" }); //scrolling
};

export const VerifyRecaptcha = (token) => {
  try {
    fetch(
      `https://www.google.com/recaptcha/api/siteverify?secret=${ReCaptcha_Secret_Key}&response=${token}`,
      {
        method: "POST",
        headers: {
          'Access-Control-Allow-Origin':'*'
        }
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return null;
        }
      })
      .then((response) => {
        console.log(response.data);
        return true;
      })
      .catch((err) => {
        return false;
      });
  } catch (error) {
    return false;
  }
};
