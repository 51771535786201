import React, { lazy, Suspense, useEffect, useState } from "react";
import Select from "react-select";
// import DatePicker from "react-datepicker";
// import moment from "moment";
// import env from "react-dotenv";
// import ReactPaginate from "react-paginate";
import { FormatDate } from "../Utils/formatters.utils";
import {
  GET_CASES_ENDPOINT_CONSTANT,
  AUTH_HEADER_TOKEN,
  Table_Case_Number_CHN,
  Table_Case_Title_CHN,
  Table_Court_Name_CHN,
  Table_Date_Filed_CHN,
  Table_Export_CHN,
  Table_Trademarks_CHN,
  Table_Next_Nav_CHN,
  Table_Previous_Nav_CHN,
  Table_Loading_CHN,
  Table_Trademark_Note_Text_CHN,
  API_HOST,
} from "../Constants/backend-api.constant";
import { focusCaseListingContent } from "./_common/case-list.utils";
import {
  getPageContentUtil,
  getPageContentsFromResultsUtil,
} from "./_common/page-content.utils";
import { ReCaptcha_Site_Key } from "../Constants/recaptch.constant";
import LoadingBlock from "../Essentials/LoadingBlock";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

// Components
const Header = lazy(() => import("../Essentials/Header"));
const Footer = lazy(() => import("../Essentials/Footer"));
const Attorney = lazy(() => import("../Essentials/Attorney"));
const ReactPaginate = lazy(() => import("react-paginate"));

const CaseList = () => {
  const english = "eng";
  const chinese = "chn";
  const currTranslation = localStorage.getItem("translation");
  let options = [
    { value: "", label: "Select Nature of Suit" },
    { value: "all", label: "ALL" },
    { value: "820", label: "Copyright" },
    { value: "830", label: "Patent" },
    { value: "840", label: "Trademark" },
  ];
  if (currTranslation == chinese) {
    options = [
      { value: "", label: "选择诉讼性质" },
      { value: "all", label: "全部" },
      { value: "820", label: "版权" },
      { value: "830", label: "专利" },
      { value: "840", label: "商标" },
    ];
  }

  const [natureOfSuit, setNatureOfSuit] = useState("all");

  const today = new Date();
  const currDate = new Date(new Date().setDate(today.getDate() - 1));
  const prevDate = new Date(new Date().setDate(today.getDate() - 31));
  const [startDate, setStartDate] = useState(new Intl.DateTimeFormat('fr-CA',{year:"numeric", month:"2-digit",day:"2-digit"}).format(prevDate).split(" ").join("-"))
  const [endDate, setEndDate] = useState(new Intl.DateTimeFormat('fr-CA',{year:"numeric", month:"2-digit",day:"2-digit"}).format(currDate).split(" ").join("-"))
  
  const [caseList, setCaseList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCaseCount, setTotalCaseCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20;
  const pageCount = Math.ceil(totalCaseCount / itemsPerPage);
  const [noResult, setNotResult] = useState(false);

  const translation = localStorage.getItem("translation");

  // Page Content
  const [bannerText, setBannerText] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [searchFilterHeadingText, setSearchFilterHeadingText] = useState("");
  const [searchFilterButtonText, setSearchFilterButtonText] = useState("");

  useEffect(() => {
    getCasesList();
    getPageContentUtil("home", translation)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response?.results?.length) {
          const home = getPageContentsFromResultsUtil(
            response?.results,
            "home"
          );
          // Banner Text
          const bannerText = home.find(
            (data) => data.item_name == "banner_text"
          );
          setBannerText(bannerText?.english_text);
          if (currTranslation == chinese) {
            setBannerText(bannerText.chinese_text);
          }
          // Banner Image
          const bannerImg = home.find(
            (data) => data.item_name == "banner_image"
          );
          const bannerImagePath = `${API_HOST}/uploads/${bannerImg.english_text}`;
          setBannerImage(bannerImagePath);

          // Search filter heading text
          const searchFilterHeading = home.find(
            (data) => data.item_name == "search_filter_text"
          );
          setSearchFilterHeadingText(searchFilterHeading?.english_text);
          if (currTranslation == chinese) {
            setSearchFilterHeadingText(searchFilterHeading.chinese_text);
          }
          // Search Button Text
          const searchFilterBtnText = home.find(
            (data) => data.item_name == "search_button_text"
          );
          setSearchFilterButtonText(searchFilterBtnText?.english_text);
          if (currTranslation == chinese) {
            setSearchFilterButtonText(searchFilterBtnText.chinese_text);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * Get cases list
   */
  const getCasesList = (currentPage = null) => {
    const loaderBlocker = document.querySelector(".loaderBlocker");
    loaderBlocker.style.display = "block";
    let page = pageNumber;
    if (currentPage) page = currentPage;
    const dateFrom = startDate;
    const dateTo = endDate;
    fetch(
      `${GET_CASES_ENDPOINT_CONSTANT}?page=${page}&natureOfSuit=${natureOfSuit}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      {
        method: "Get",
        headers: {
          "Content-Type": "application/json",
          token: AUTH_HEADER_TOKEN,
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          loaderBlocker.style.display = "none";
          return response.json();
        }
        throw response;
      })
      .then((casesData) => {
        setCaseList(casesData?.cases);
        setTotalCaseCount(casesData?.totalCount);
        if (!casesData?.totalCount) {
          setNotResult(true);
        } else {
          setNotResult(false);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleSearchButton = () => {
    setCaseList([]);
    getCasesList();
    focusCaseListingContent("caseListingContent");
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const currPage = Number(event.selected) + 1;
    const newOffset = (event.selected * itemsPerPage) % totalCaseCount;
    setItemOffset(newOffset);
    setCaseList([]);
    getCasesList(currPage);
    focusCaseListingContent("caseListingContent");
  };

  /**
   * Handle Export Link with Captcha
   * @param {*} link
   */
  const handleExportLink = (link) => {
    confirmAlert({
      title: "Confirm to Export",
      message: "Are you sure you want to export this trademarks file?",
      buttons: [
        {
          label: "Yes",
          onClick: () => (window.location = link),
        },
        {
          label: "No",
          onClick: () => "",
        },
      ],
    });
  };



  return (
    <div>
      <LoadingBlock />
      <Suspense fallback={LoadingBlock()}>
        <Header />
      </Suspense>
      <div
        className="slider"
        style={{ backgroundImage: `url(${bannerImage})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 bannerTextArea">
              <h1>
                {bannerText ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: bannerText,
                    }}
                  ></span>
                ) : (
                  ""
                )}
              </h1>
            </div>
            <div className="col-md-6">
              <form>
                <h2>
                  {searchFilterHeadingText ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: searchFilterHeadingText,
                      }}
                    ></span>
                  ) : (
                    "Search for Cases"
                  )}
                </h2>
                <div className="row">
                  <div className="col-12">
                  <label style={{display: 'none'}} htmlFor="select_nature_of_suit"> Select </label>
                    <Select
                      placeholder={<div>Select Nature of Suit</div>}
                      options={options}
                      defaultValue={{
                        label: currTranslation == chinese ? "全部" : "ALL",
                        value: "all",
                      }}
                      onChange={(item) => setNatureOfSuit(item.value)}
                      name="select_nature_of_suit"
                    />
                     
                  </div>
                  <div className="col-6">
                  <label style={{display: 'none'}} htmlFor="date_start"> Start Date </label>
                    {/* <DatePicker
                      className="datePick"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      name="date_start"
                    /> */}
                    <input type="date" className="datePick" onChange={(e)=> setStartDate(e.target.value)} name="date_start" value={startDate}/>
                   
                  </div>
                  <div className="col-6">
                  <label style={{display: 'none'}} htmlFor="date_end"> End Date </label>
                    {/* <DatePicker
                      className="datePick"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      name="date_end"
                    /> */}
                   <input type="date" className="datePick" onChange={(e)=> setEndDate(e.target.value)} name="date_end" value={endDate}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4 offset-8">
                    <button
                      className="searchForm"
                      onClick={handleSearchButton}
                      type="button"
                    >
                      {searchFilterButtonText ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: searchFilterButtonText,
                          }}
                        ></span>
                      ) : (
                        "Search"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="content"><h3>{caseLoading}</h3></div> */}
      <div className="content" id="caseListingContent">
        <div className="container">
          <div className="col-12">
            <div className="tableContent">
              
              {!noResult ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        {currTranslation == chinese
                          ? Table_Date_Filed_CHN
                          : "Date Filed"}
                      </th>
                      <th scope="col">
                        {currTranslation == chinese
                          ? Table_Case_Title_CHN
                          : "Case Title"}
                      </th>
                      <th scope="col">
                        {currTranslation == chinese
                          ? Table_Case_Number_CHN
                          : "Case Number"}
                      </th>
                      <th scope="col">
                        {currTranslation == chinese
                          ? Table_Court_Name_CHN
                          : "Court"}
                      </th>
                      <th scope="col" width="100">
                        {currTranslation == chinese
                          ? Table_Trademarks_CHN
                          : "Trademarks*"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {caseList.length ? (
                      caseList.map((caseItem) => (
                        <tr key={caseItem.id}>
                          <td>
                         
                            {FormatDate(caseItem.date_filed)}
                          </td>
                          <td>
                            <div>
                              <a href={"/case-detail/" + caseItem.id}>
                                {caseItem.case_title}
                              </a>
                              {/* <p>
                              <span>Plaintiff:</span>
                              {caseItem.plaintiff_name}
                            </p>
                            <p>
                              <span>Defendant:</span>
                              {caseItem.defendant_name}
                            </p> */}
                            </div>
                          </td>
                          <td>{caseItem.case_full_number}</td>
                          <td>{caseItem.court_name}</td>
                          <td>
                            {caseItem.export_trademarks ? (
                              <button
                                className="exportButton"
                                onClick={() => 
                                  handleExportLink(caseItem.export_trademarks)
                                }
                              >
                                {currTranslation == chinese
                                  ? Table_Export_CHN
                                  : "Export"}
                              </button>
                            ) : (
                              <p style={{ color: "rgb(48 129 200 / 77%)" }}>
                                {currTranslation == chinese
                                  ? Table_Export_CHN
                                  : "Export"}
                              </p>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={5}>
                          <p className="loading-case">
                            {currTranslation == chinese
                              ? Table_Loading_CHN
                              : "Loading... Please wait"}
                          </p>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={5}>
                        {currTranslation == chinese
                          ? Table_Trademark_Note_Text_CHN
                          : "* Trademark data may not be available for all cases."}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div className="loading-case"> No Result Found </div>
              )}
            </div>
            <div className="col-12">
              <div className="table-footer">
                <Suspense fallback={LoadingBlock()}>
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      currTranslation == chinese ? Table_Next_Nav_CHN : "NEXT"
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel={
                      currTranslation == chinese
                        ? Table_Previous_Nav_CHN
                        : "PREVIOUS"
                    }
                    renderOnZeroPageCount={null}
                    activeClassName="paginationActive"
                  />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={LoadingBlock()}>
        <Attorney></Attorney>
        <Footer></Footer>
      </Suspense>
    </div>
  );
};

export default CaseList;
