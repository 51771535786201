import React, { lazy, Suspense, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useSearchParams } from "react-router-dom";
import { FormatDate } from "../Utils/formatters.utils";
import {
  SEARCH_ENDPOINT,
  AUTH_HEADER_TOKEN,
  Table_Case_Number_CHN,
  Table_Case_Title_CHN,
  Table_Court_Name_CHN,
  Table_Date_Filed_CHN,
  Table_Export_CHN,
  Table_Trademarks_CHN,
  Table_Next_Nav_CHN,
  Table_Previous_Nav_CHN,
  Table_Loading_CHN,
  Table_Trademark_Note_Text_CHN,
  Search_Term_Text_CHN,
} from "../Constants/backend-api.constant";

import { focusCaseListingContent } from "./_common/case-list.utils";
import { ReCaptcha_Site_Key } from "../Constants/recaptch.constant";
import ReCAPTCHA from "react-google-recaptcha";

// Components
import LoadingBlock from "../Essentials/LoadingBlock";
const Header = lazy(() => import("../Essentials/Header"));
const Footer = lazy(() => import("../Essentials/Footer"));
const Attorney = lazy(() => import("../Essentials/Attorney"));

const recaptchaRef = React.createRef();

const SearchCases = () => {
  const chinese = "chn";
  const currTranslation = localStorage.getItem("translation");
  const [searchParams, setSearchParams] = useSearchParams();
  const searchTerm = searchParams.get("term");
  const [caseList, setCaseList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalCaseCount, setTotalCaseCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20;
  const pageCount = Math.ceil(totalCaseCount / itemsPerPage);
  const [noResult, setNotResult] = useState(false);

  useEffect(() => {
    SearchTermInCases();
  }, []);

  /**
   * Get cases list
   */
  const SearchTermInCases = (currentPage = null) => {
    let page = pageNumber;
    if (currentPage) page = currentPage;
    fetch(`${SEARCH_ENDPOINT}?page=${page}&key=${encodeURI(searchTerm)}`, {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        token: AUTH_HEADER_TOKEN,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((casesData) => {
        setCaseList(casesData?.cases);
        setTotalCaseCount(casesData?.totalCount);
        if (!casesData?.totalCount) {
          setNotResult(true);
        } else {
          setNotResult(false);
        }
      })
      .catch((err) => console.log(err));
  };

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const currPage = Number(event.selected) + 1;
    const newOffset = (event.selected * itemsPerPage) % totalCaseCount;
    setCaseList([]);
    setItemOffset(newOffset);
    SearchTermInCases(currPage);
    focusCaseListingContent("caseListingContent");
  };

  /**
   * Handle Export Link with Captcha
   * @param {*} link
   */
  const handleExportLink = async (link) => {
    const token = await recaptchaRef.current.executeAsync();
    //const isValidToken = await VerifyRecaptcha(token);
    if (link && token) {
      window.location = link;
    }
    recaptchaRef.current.reset();
  };

  return (
    <div>
      <LoadingBlock />
      <Suspense fallback={LoadingBlock()}>
        <Header />
      </Suspense>
      <div className="content" id="caseListingContent">
        <div className="container">
          <div className="col-12">
            <div>
              <h5>
                {currTranslation == chinese
                  ? Search_Term_Text_CHN
                  : "Search Term"}
                : {searchTerm}
              </h5>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={ReCaptcha_Site_Key}
              />
            </div>
            {!noResult ? (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">
                      {currTranslation == chinese
                        ? Table_Date_Filed_CHN
                        : "Date Filed"}
                    </th>
                    <th scope="col">
                      {currTranslation == chinese
                        ? Table_Case_Title_CHN
                        : "Case Title"}
                    </th>
                    <th scope="col">
                      {currTranslation == chinese
                        ? Table_Case_Number_CHN
                        : "Case Number"}
                    </th>
                    <th scope="col">
                      {currTranslation == chinese
                        ? Table_Court_Name_CHN
                        : "Court"}
                    </th>
                    <th scope="col" width="100">
                      {currTranslation == chinese
                        ? Table_Trademarks_CHN
                        : "Trademarks*"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {caseList.length ? (
                    caseList.map((caseItem) => (
                      <tr key={caseItem.id}>
                        <td>
                          {FormatDate(caseItem.date_filed)}
                        </td>
                        <td>
                          <div>
                            <a href={"/case-detail/" + caseItem.id}>
                              {caseItem.case_title}
                            </a>
                          </div>
                        </td>
                        <td>{caseItem.case_full_number}</td>
                        <td>{caseItem.court_name}</td>
                        <td>
                          {caseItem.export_trademarks ? (
                            <button
                              className="exportButton"
                              onClick={() =>
                                handleExportLink(caseItem.export_trademarks)
                              }
                            >
                              {currTranslation == chinese
                                ? Table_Export_CHN
                                : "Export"}
                            </button>
                          ) : (
                            <a style={{ color: "rgb(48 129 200 / 77%)" }}>
                              {currTranslation == chinese
                                ? Table_Export_CHN
                                : "Export"}
                            </a>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={5}>
                        <p className="loading-case">
                          {currTranslation == chinese
                            ? Table_Loading_CHN
                            : "Loading... Please wait"}
                        </p>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={5}>
                      {currTranslation == chinese
                        ? Table_Trademark_Note_Text_CHN
                        : "* Trademark data may not be available for all cases."}
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className="loading-case">No Result Found</div>
            )}
          </div>
          <div className="col-12">
            <div className="table-footer">
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  currTranslation == chinese ? Table_Next_Nav_CHN : "NEXT"
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={
                  currTranslation == chinese
                    ? Table_Previous_Nav_CHN
                    : "PREVIOUS"
                }
                renderOnZeroPageCount={null}
                activeClassName="paginationActive"
              />
            </div>
          </div>
        </div>
      </div>
      <Suspense fallback={LoadingBlock()}>
        <Attorney></Attorney>
        <Footer></Footer>
      </Suspense>
    </div>
  );
};

export default SearchCases;
