const LoadingBlock = () => {
  return (
    <div className="loaderBlocker">
      <div className="fadeOut"></div>
      <div className="loader"></div>
    </div>
  );
};

export default LoadingBlock;
