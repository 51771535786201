import React from "react";
// import { Redirect } from "react-router-dom"

import { BrowserRouter, Routes, Route } from "react-router-dom";

// Components
import CaseList from "../Components/CaseList";
import About from "../Components/About";
import CaseDetail from "../Components/CaseDetail";
import SearchCases from "../Components/Search";
import TermsOfUse from "../Components/TermsOfUse";
// import ProductDetail from "../Components/ProductDetail"

const allRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<CaseList title="CaseList" />}></Route>
        <Route path="/about" element={<About title="About" />}></Route>
        <Route
          path="/case-detail/:id"
          element={<CaseDetail title="Case Detail" />}
        ></Route>
        <Route
          path="/search"
          element={<SearchCases title="Search" />}
        ></Route>
        <Route
          path="/terms-of-use"
          element={<TermsOfUse title="Terms Of Use" />}
        ></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default allRoutes;
