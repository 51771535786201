import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import Logo from "./../assets/img/logo.png";
import Search from "./../assets/img/search.png";
import { API_HOST } from "../Constants/backend-api.constant";
import {
  getPageContentUtil,
  getPageContentsFromResultsUtil,
} from "../Components/_common/page-content.utils";
import { Tooltip } from 'react-tooltip';

const Header = (props) => {
  const english = "eng";
  const chinese = "chn";
  const [translation, setTranslation] = useState("");
  const [caseListMenuText, setCaseListMenuText] = useState("");
  const [aboutMenuText, setAboutMenuText] = useState("");
  const [phoneNumberText, setPhoneNumberText] = useState("");
  const [phoneNumberLabelText, setPhoneNumberLabelText] = useState("");
  const [searchBarText, setSearchBarText] = useState("");
  const [headerLogo, setHeaderLogo] = useState("");

  //const pageData = props.content;
  const currTranslation = localStorage.getItem("translation");
  useEffect(() => {
    if (translation) localStorage.setItem("translation", translation);

    getPageContentUtil("header", translation)
    .then((response) => {
      return response.json()
    }).then((response) => {
      
      if(response?.results?.length) {

        const pageData = getPageContentsFromResultsUtil(
          response?.results,
          "header"
        );
             
        if (pageData?.length) {
          // Case List
          const caseList = pageData.find(
            (data) => data.item_name == "top_menu_case_list"
          );
          setCaseListMenuText(caseList?.english_text);
          if (currTranslation == chinese) {
            setCaseListMenuText(caseList.chinese_text);
          }
          // About
          const aboutMenu = pageData.find(
            (data) => data.item_name == "top_menu_about"
          );
          setAboutMenuText(aboutMenu?.english_text);
          if (currTranslation == chinese) {
            setAboutMenuText(aboutMenu.chinese_text);
          }
          // Searchbar Text
          const searchBar = pageData.find(
            (data) => data.item_name == "search_bar_text"
          );
          setSearchBarText(searchBar?.english_text);
          if (currTranslation == chinese) {
            setSearchBarText(searchBar.chinese_text);
          }
          // Phone Number
          const phoneNumberText = pageData.find(
            (data) => data.item_name == "phone_number"
          );
          setPhoneNumberText(phoneNumberText?.english_text);
          if (currTranslation == chinese) {
            setPhoneNumberText(phoneNumberText.chinese_text);
          }
          // Phone Number Heading/Label
          const phoneNumberLabelText = pageData.find(
            (data) => data.item_name == "phone_number_label"
          );
          setPhoneNumberLabelText(phoneNumberLabelText?.english_text);
          if (currTranslation == chinese) {
            setPhoneNumberLabelText(phoneNumberLabelText.chinese_text);
          }
    
          // Logo
          const logo = pageData.find((data) => data.item_name == "logo");
          const headerLogoPath = `${API_HOST}/uploads/${logo.english_text}`;
          setHeaderLogo(headerLogoPath);
        }
      }
    })   
  });
  const phoneNumberTextSpan = (
    <span dangerouslySetInnerHTML={{ __html: phoneNumberText }}></span>
  );
  return (
    <header>
      <div className="container">
        <div className="row ">
          <div className="col-md-2">
            <div className="logo">
              <a href="/">
                <img src={headerLogo ? headerLogo : ""} alt="Logo" width={300} height={25}></img>
              </a>
            </div>
          </div>
          <div className="col-md-3">
            <ul className="menu">
              <li>
                <NavLink activeClassName="is-active" to={"/"}>
                  {caseListMenuText ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: caseListMenuText,
                      }}
                    ></span>
                  ) : (
                    "Case List"
                  )}
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName="is-active" to={"/about"}>
                  {aboutMenuText ? (
                    <span
                      dangerouslySetInnerHTML={{
                        __html: aboutMenuText,
                      }}
                    ></span>
                  ) : (
                    "About"
                  )}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col-md-7 forcustomdiv">
            <ul className="menu2">
              <li className="seperator">
                {phoneNumberLabelText ? phoneNumberLabelText : "T"}:
                <a href={"tel:" + phoneNumberText.replace(/(<([^>]+)>)/ig, '')}>
                  {phoneNumberText ? (
                    phoneNumberTextSpan
                  ) : (
                    <span>(312) 715-7312</span>
                  )}
                </a>
              </li>
              <li>
                <a
                  className="bold"
                  href="/"
                  onClick={(e) => setTranslation(english)}
                >
                  EN
                </a>
              </li>
              <li>
                <a
                  className="bold"
                  href="/"
                  onClick={(e) => setTranslation(chinese)}
                >
                  中国
                </a>
              </li>
            </ul>

            <form action="/search" method="get" className="searchBarForm">
            
              <div className="searchBar" data-tooltip-id="search-tooltip" data-tooltip-content={
                    searchBarText ? searchBarText : "Search by Plaintiff or Last Five Digits of Case Number"
                  } data-tooltip-place="left-start">
                <input 
                  type="text"
                  placeholder={
                    currTranslation == chinese ? '搜索' : "Search"
                  }
                  name="term"
                ></input>
                <button className="search" type="submit">
                  <img src={Search} alt="search" width={26} height={26}></img>
                </button>
              </div>
              <Tooltip  id="search-tooltip"/>
            </form>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
